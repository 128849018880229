import React, { useState } from "react";
import { useHistory } from "react-router";
import api from "../Utils/apiClient";

import { useLocation } from "react-router-dom";
import Logo from "../image/images/logo.png";
var CryptoJS = require("crypto-js");

function Comment() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  var bytes = CryptoJS.enc.Base64.parse(id).toString(CryptoJS.enc.Utf8);

  const [message, setMessage] = useState("");
  let history = useHistory();

  const LikeAndDislike = (key) => {
    let data = {
      key: key,
      id: bytes,
    };
    api
      .post("reviewMangement/LikeAndDislike", data)
      .then((res) => {
        if (res.success) {
        }
      })
      .catch((err) => {
        console.log("setLock err", err);
      });
  };

  function Movepage() {
    history.push("/Page3");
  }

  return (
    <div class="review-wrap">
      <div class="review-box">
        <div class="logo">
          <img src={Logo} alt="" />
        </div>

        <p>Please choose one of the below to leave your review.</p>
        <div class="comments-input">
          <label>Comments</label>
          <textarea
            class="Enter your commments here"
            name="textValue"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button onClick={() => [LikeAndDislike(message), Movepage()]}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
export default Comment;
