import React, { useEffect, useState } from "react";
import api from "../Utils/apiClient";
import { useHistory } from "react-router-dom";
import happy from "../image/images/happy.svg";
import sad from "../image/images/sad.svg";
import Logo from "../image/images/logo.png";
import { useLocation } from "react-router-dom";

var CryptoJS = require("crypto-js");
function Page1() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const bytes = CryptoJS.enc.Base64.parse(id).toString(CryptoJS.enc.Utf8);
  const [isPage1, setIsPage1] = useState(false);

  useEffect(() => {
    SendDeliveredEmail(bytes);
  });

  const SendDeliveredEmail = (bytes) => {
    debugger;
    let data = {
      id: bytes,
    };

    api
      .post("reviewMangement/SendDeliveredEmail", data)
      .then((res) => {
        if (res.success) {
          if (res.Data.length <= 0) {
            setIsPage1(true);
            history.push("/Page1/?id=" + id);
          } else if (
            res.Data[0].Commnet == null &&
            res.Data[0].Review == "like"
          ) {
            history.push("/Page2/?id=" + id);
          } else if (
            res.Data[0].Commnet == null &&
            res.Data[0].Review == "Dislike"
          ) {
            history.push("/Comment/?id=" + id);
          } else if (
            res.Data[0].Commnet != null &&
            res.Data[0].Review == "Dislike" &&
            res.Data[0].Status == "DONE"
          ) {
            history.push("/Page3");
          }
        }
      })
      .catch((err) => {
        console.log("setLock err", err);
      });
  };

  let history = useHistory();
  const LikeAndDislike = (key) => {
    let data = {
      key: key,
      id: bytes,
    };

    api
      .post("reviewMangement/LikeAndDislike", data)
      .then((res) => {
        if (res.success) {
          if (key == "like") {
            Nextpage(bytes);
          } else {
            Commentpage(bytes);
          }
        }
      })
      .catch((err) => {
        console.log("setLock err", err);
      });
  };

  function Nextpage() {
    history.push("/Page2/?id=" + id);
  }

  function Commentpage() {
    history.push("/Comment/?id=" + id);
  }

  return (
    <div class="review-wrap">
      {isPage1 ? (
        <div class="review-box">
          <div class="logo">
            <img src={Logo} alt="" />
          </div>
          <p>How satisfied were you with the overall experience?</p>

          <div class="review-smiley">
            <a class="sad" onClick={() => LikeAndDislike("like")}>
              <img src={happy} alt="" />
            </a>
            <a class="happy" onClick={() => LikeAndDislike("Dislike")}>
              <img src={sad} alt="" />
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default Page1;
