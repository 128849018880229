import logo from './logo.svg';
import './App.css';
import Page1 from './Page/Page1';
import Page2 from './Page/Page2';
import Page3 from './Page/Page3';
import Comment from './Page/Comment';
import { BrowserRouter as Router, Switch, Route,Redirect} from "react-router-dom";
function App() {
  return (
    <Router>
  <Switch>
  
        <Route path='/Page1' component={Page1}/>
        <Route path='/Page2' component={Page2}/>
        <Route path='/Page3' component={Page3}/>
        <Route path='/Comment' component={Comment}/>
</Switch>
     
      </Router>
  );
}

export default App;
