import React, { useEffect, useState } from "react";
import api from "../Utils/apiClient";
import Logo from "../image/images/logo.png";
import { useLocation } from "react-router-dom";

var CryptoJS = require("crypto-js");

export default function Page2(props) {
  const [list, setList] = useState([]);
  const search = useLocation().search;

  const id = new URLSearchParams(search).get("id");
  var bytes = CryptoJS.enc.Base64.parse(id).toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    ReviewSite();
  }, []);

  const ReviewSite = () => {
    api
      .post("reviewMangement/reviewsite")
      .then((res) => {
        if (res.success) {
          setList(res.Data);
        } else {
          //  cogoToast.error(res.message);
        }
      })
      .catch((err) => {
        console.log("setLock err", err);
      });
  };

  const displayReviewSites = () => {
    return list.map((data) => {
      return (
        <a
          href={data.BusinessReviewPage}
          onClick={() => SocialSite(data.SiteName)}
        >
          <span className="review-site-logo">
            <img src={data.SiteLogo} alt="" />
          </span>
          {data.SiteName}
        </a>
      );
    });
  };

  const SocialSite = (key) => {
    let data = {
      key: key,
      id: bytes,
    };
    api
      .post("reviewMangement/SocialSite", data)
      .then((res) => {})
      .catch((err) => {
        console.log("setLock err", err);
      });
  };

  return (
    <div class="review-wrap">
      <div class="review-box">
        <div class="logo">
          <img src={Logo} alt="" />
        </div>
        <p>Please choose one of the below to leave your review.</p>
        <div class="review-site">{displayReviewSites()}</div>
      </div>
    </div>
  );
}
