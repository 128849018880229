import React from "react";
import { useHistory } from "react-router-dom";
import Logo from "../image/images/logo.png";
import tick from "../image/images/tick.svg";
function Page3() {
  let history = useHistory();
  return (
    <div class="review-wrap">
      <div class="review-box">
        <div class="logo">
          <img src={Logo} alt="" />
        </div>
        <h2>THANK YOU!</h2>
        <div class="tick">
          <img src={tick} alt="" />
        </div>
        <p>Thank you for taking the time to share your valuable feedback.</p>
      </div>
    </div>
  );
}
export default Page3;
